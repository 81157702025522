import React, { useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import { STIcon } from 'components/Brand/Icons/STIcon';
import useBreakpointView from 'utils/useBreakpointView';
import Tooltip from 'components/Core/Tooltip';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { getEntry, getEntries } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import WebsiteImage from 'components/WebsiteImage';
import './styles.less';
import { StyledLayout } from './styles';

const ValuePropStrip = ({ entries = [] }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const props = entries?.filter((entry) => entry.templateId.includes('main-title'));
  const isMobile = useBreakpointView(['xs', 'sm', 'md']);
  const isTablet = useBreakpointView(['lg']);

  const checkTooltip = (index) =>
    entries.find((entry) => entry.templateId === `prop-${index}-hover-modal-title`);

  const getTooltip = (id) => {
    const images = getEntries(`prop-${id}-hover-image`, entries);

    const tooltipContent = (
      <>
        <div className="hover-modal-main-title">
          <ContentfulRichText richText={getEntry(`prop-${id}-hover-modal-title`, entries)} />
        </div>
        <Row gutter={60} className="hover-modal-logo-section">
          {images?.map((image, index) => (
            <Col xs={24} sm={24 / images.length} className={`image-copy image-${index + 1}`}>
              <a href={image.caption}>
                <WebsiteImage {...image} fullResolution />
              </a>
              <ContentfulRichText
                richText={getEntry(`prop-${id}-hover-description-${index + 1}`, entries)}
              />
            </Col>
          ))}
        </Row>
      </>
    );
    return (
      checkTooltip(id) && (
        <Tooltip
          title={tooltipContent}
          className="value-prop-strip--tooltip"
          dataCy="careersTooltip"
          placement={isMobile ? 'bottom' : isTablet ? 'bottom-start' : 'bottom-end'}
          open={isTooltipVisible}
          tooltipStyle={{
            maxWidth: isMobile ? 350 : 650,
            textAlign: 'center',
            padding: '25px 30px',
          }}
        >
          <STIcon type="icon-info-circle" />
        </Tooltip>
      )
    );
  };

  return (
    <StyledLayout>
      <Row gutter={40}>
        {props?.map((prop, index) => {
          const id = index + 1;
          const hasTooltip = checkTooltip(id);

          return (
            <Col
              className={`value-strip-inner-container ${
                isTooltipVisible && hasTooltip ? 'hovering' : ''
              }`}
              xs={12}
              lg={6}
              onMouseEnter={() => hasTooltip && setIsTooltipVisible(true)}
              onMouseLeave={() => hasTooltip && setIsTooltipVisible(false)}
            >
              <div className="value-strip-copy">
                <ContentfulRichText richText={getEntry(`prop-${id}-main-title`, entries)} />
                <div className="description-container">
                  <ContentfulRichText richText={getEntry(`prop-${id}-main-description`, entries)} />
                  {getTooltip(id)}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </StyledLayout>
  );
};

export default ValuePropStrip;
