import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const StyledLayout = styled(SectionLayout)`
  .section-layout--inner-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  h2 {
    font-size: var(--font-size-h1);
  }

  h2,
  p {
    color: var(--darkest);
    margin: 0;
  }

  .MuiGrid2-root {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 25px;
  }

  .hovering {
    cursor: pointer;
  }

  .rich-text--container {
    width: max-content;
  }

  .hover-modal-main-title {
    margin-bottom: 10px;
  }

  .value-strip-inner-container {
    justify-content: center;
  }

  .value-strip-copy {
    display: flex;
    align-items: center;
  }

  .value-prop-strip--tooltip {
    margin: 0 0 8px 10px;
    display: flex;
    align-self: end;
  }

  path {
    fill: #c4c4c4;
  }

  .description-container {
    display: flex;

    .rich-text--container {
      margin-left: 20px;
    }
  }

  @media (min-width: 991px) {
    .description-container {
      max-width: 100px;
    }
  }

  @media (max-width: 1100px) {
    .value-strip-inner-container {
      justify-content: flex-start;
    }
  }

  @media (max-width: 767px) {
    .section-layout--inner-container {
      padding: 0 28px;
    }
  }

  @media (max-width: 575px) {
    h2,
    p {
      text-align: left;
    }

    h2 {
      margin-bottom: 5px;
      font-size: var(--font-size-mobile-h1);
    }

    p {
      font-size: var(--font-size-mobile-p-sm);
    }

    .description-container {
      .rich-text--container {
        margin-left: 0;
      }
    }

    .value-prop-strip--tooltip {
      margin: 0 0 4px 10px;
    }

    .value-strip-copy {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export { StyledLayout };
